import Vue from 'vue'
import Router from 'vue-router'
import Cars from '../components/Damage2'
import Contact from '../components/Damage6'
import Main from '../components/Damage1'
import Description from '../components/Damage4'
import Form from '../components/Damage3'
import Functions from '../components/Damage4'

Vue.use(Router)
const router=new Router({
  routes:[
    {
      path:'/customer-complain',
      component:Main,
      name:'Main',
    },
    {
      path:'/customer-complain/:arg',
      component:Cars,
      name:'Cars'
    },
    {
      path:'/contact',
      component:Contact,
      name:'Contact'
    },
    {
      path:'/description',
      component:Description,
      name:'Description'
    },
    {
      path:'/form',
      component:Form,
      name:'Form'
    },
    {
      path:'/functions',
      component:Functions,
      name:'Functions'
    }
  ],

  mode:'history'
})
export default router

// router.beforeEach((to, from, next) => {
//   next({
//         path: '/customer-complain?hash=c56cf9 ',
//       })
//  })
