<template>
  <div class="main pa-6 mb-auto">
         <div class="mt-4">

             <h2 class="text-heading-3 red--text text-center mt-2">
                 Neodjíždějte s parkoviště!
             </h2>
             <p class="text-subtitle-1 mt-6 text">
                Vaši reklamaci jsme uložili a upozornili odpovědného pracovníka. Pro urychlení situace můžete zavolat na naši hotline:
             </p>

             <div class="phone-box d-flex align-center mt-8 mb-4">
                 <img src="./img/Vector.svg" alt="phone icon" class="phone">
                 <a href="tel:+420 709 316 546" class="number-link ml-5"> <span>+420</span> 709 316 546</a>
             </div>
         </div>
  </div>
   
</template>

<script>
export default {
  name: 'Damage6'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .main {
    background-color: white;
    max-width: 480px;
    max-height: 720px;
    box-sizing: border-box;
  }  

  .phone {
      max-width: 100%;
  }

  .text {
    text-align: left;
  }

  .number-link {
      color: black;
      text-decoration: none; 
      font-size: 28px;
      line-height: 24px;
  }

  span {
      color: #c4c4c4;
  }



</style>
