<template>
  <div class="main pa-6">
        <p class="text-body-1 text">
            Děkujeme, Vaši reklamaci jsme zaznamenali a budeme v co nejkratší době řešit.
  <br>
  <br>
            <span class="span">
                 S pozdravem, <br>
              team SmileParkings
            </span>
        </p>

        <h2 class="text-heading-3 text-center mt-6 ">
            Nyní můžete odjet z parkoviště.
        </h2>

        <div class="d-flex flex-column align-center mt-6 justify-center">
            <router-link  to="/contact">
                Chci mluvit s managerem!
            </router-link>

            <p class="text-center text-caption">Mám akutní věc, kterou potřebuji okamžitě probrat.</p>
        </div>

  </div>

</template>

<script>

export default {
  name: 'Damage4',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .main {
    background-color: white;
    max-width: 480px;
    max-height: 720px;
    box-sizing: border-box;
  }

  .span {
    font-style: italic;
  }

  .text {
    text-align: left;
  }


</style>
