import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    testForm: {
      image: "",
      image2: "",
      image3: "",
      image4: "",
      id: Date.now(),
      topic: "",
      textarea: ""
    },
    data: []
  },
  mutations: {
    setTestFormByKey(state, payload) {
      state.testForm = {
        ...state.testForm,
        ...payload
      };
    }
  },
  actions: {},
  getters: {
    DataComplain(state) {
      return state.data;
    }
  }
});
export default store;
