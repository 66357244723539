<template>
  <div class="main pa-6">
        <p class="text-subtitle-1 text">
            Popište rozsah a závažnost poškození vozu, vyjmenujte případné chybějící předměty:
        </p>

      <div class="textarea-box d-flex justify-center align-center">
        <textarea :value="testForm.textarea" @input="onChange($event)" class="textarea d-block"></textarea>
      </div>

      <v-btn
        block
        color="#052B41"
        depressed
        large
        class="mt-4 rounded-lg"
        to="/description"
        >
          <span class="span" @click="Submit">Pokračovat >></span>
        </v-btn>
    </div>

</template>

<script>
import axios from 'axios'
import {mapMutations,mapState,mapGetters} from 'vuex'
export default {
  name: 'Personal1',
  arr:[],
  methods:{
  ...mapMutations(['setTestFormByKey']),
   onChange(arg){
      this.setTestFormByKey({textarea:arg.target.value})
   },
   Submit(){
     this.DataComplain.push(this.testForm)
     axios.post('https://www.smileparking.cz/service.getreservation?hash=c56cf9',this.testForm)
     .then((response)=> {
       console.log(response,'fewfew');
        })
        .catch((error)=>{
          console.log(error,'error');
        });
   }


 },
 computed:{
   ...mapState(['testForm']),
   ...mapGetters(['DataComplain'])
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .main {
    background-color: white;
    max-width: 480px;
    box-sizing: border-box;
  }
  .span {
    color: white;
  }

  .textarea {
    padding: 10px;
    background-color: #C4C4C4;
    width: 359px;
    height: 280px;
    resize: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 15px 0px;
  }

  .text {
    text-align: left;
  }
</style>
