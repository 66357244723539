<template>

  <div class="main pa-6">
    <p class="text-body-1 text">
      Nafoťte poškozená místa vozu a celkovou fotografii vozu na parkovišti.

    </p>

    <div class="content-box  ">
      <div class="first-content">
        <input type="file" class="third-input" @change="onFileChange">
        <button class="third-button">
                     <img v-if="url" :src="url" alt="car" class="car-img">
                     <img v-else src="./img/car2.svg" alt="car" class="car-img">
                   </button>
      </div>

      <div class="first-content">
        <input type="file" class="third-input" @change="onFileChange2">
        <button class="third-button">
                     <img v-if="url2" :src="url2" alt="car" class="car-img">
                     <img v-else src="./img/car2.svg" alt="car" class="car-img">
                   </button>
      </div>

      <div class="first-content">
        <input type="file" class="third-input" @change="onFileChange3">
        <button class="third-button">
                     <img v-if="url3" :src="url3" alt="car" class="car-img">
                     <img v-else src="./img/car2.svg" alt="car" class="car-img">
                   </button>
      </div>

      <div class="first-content">
        <input type="file" class="third-input" @change="onFileChange4">
        <button class="third-button">
                     <img v-if="url4" :src="url4" alt="car" class="car-img">
                     <img v-else src="./img/car2.svg" alt="car" class="car-img">
                   </button>
      </div>
    </div>

    <v-btn block
           color="#052B41"
           depressed
           large
           to="/form"
           class="mt-4 rounded-lg">
      <span class="span">Nafoceno >></span>
    </v-btn>

  </div>

</template>

<script>
import {mapMutations} from 'vuex'
  export default {
    name: 'Damage2',
    data() {
      return {
        url: null,
        url2: null,
        url3: null,
        url4: null
      }
    },
    methods: {
      onFileChange(e) {
        const file = e.target.files[0]
        this.url = URL.createObjectURL(file)
        this.setTestFormByKey({image:e.target.value})

      },
      onFileChange2(e) {
        const file = e.target.files[0]
        this.url2 = URL.createObjectURL(file)
            this.setTestFormByKey({image2:e.target.value})
      },
      onFileChange3(e) {
        const file = e.target.files[0]
        this.url3 = URL.createObjectURL(file)
            this.setTestFormByKey({image3:e.target.value})
      },
      onFileChange4(e) {
        const file = e.target.files[0]
        this.url4 = URL.createObjectURL(file)
            this.setTestFormByKey({image4:e.target.value})
      },
        ...mapMutations(['setTestFormByKey']),
      submit(url2){
        console.log(url2);
      }
    },
    computed:{

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .main {
    background-color: white;
    max-width: 480px;
    max-height: 720px;
    box-sizing: border-box;
  }
  .content-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .car-img {
    max-width: 100%;
    object-fit: cover;
  }

  .text {
    text-align: left;
  }

  .first-content {
    position: relative;
    box-sizing: border-box;
    width: 45%;
    margin-top: 15px;
  }

  .span {
    color: white;
  }

  .first-input,
  .second-input,
  .third-input,
  .fourth-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 50px 0px;
  }

  .first-button {
    left: 0%;
    background-color: #f0f0f0;
    padding: 28px 0px 28px 0px;
    border: 1px solid #999999;
    box-sizing: border-box;
  }

  .second-button {
    left: 0%;
    background-color: #f0f0f0;
    padding: 28px 0px 28px 0px;
    border: 1px solid #999999;
    box-sizing: border-box;
  }

  .third-button,
  .fourth-button,
  .third-button,
  .fourth-button {
    top: 0%;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #999999;
  }

</style>
