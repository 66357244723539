<template>

  <div class="main pa-6">
    <h2 class="text-heading-3 red--text text-center mt-4">
      Neodjíždějte s parkoviště!
    </h2>

    <p class="mt-6 text-center">
      Z následujicích možností vyberte nejzávažnější:
    </p>

    <div class="btn-group">
      <v-btn block depressed
             value="ValueOFinformation"
             large
             class="mt-6 rounded-lg font-weight-black"
             outlined
             @click="submit(`extraservice`)"
             color="#052B41">
        Poškození vozu, pojízdné >>

      </v-btn>

      <v-btn block depressed
             @click="submit(`staff`)"
             large
             class="mt-10 rounded-lg font-weight-black"
             outlined
             color="#052B41">
        Chybějící doklady či vybavení >>
      </v-btn>

      <v-btn block depressed large
             class="mt-10 rounded-lg font-weight-black"
             outlined
             @click="submit('cardamage')"
             color="#052B41">
        Nepojízdné vozidlo >>
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapMutations} from 'vuex'
  export default {
    name: 'Damage1',
    data() {
      return {
        Missing: 'Missing',
        Immobile: 'Immobile',
        Damage: 'Damage',
        name: 'Missing'
      }
    },
  methods: {
    ...mapMutations(['setTestFormByKey']),
    submit(arg){
      this.setTestFormByKey({topic:arg})
      console.log(arg);
      this.$router.push({ name: 'Cars', params: { arg: arg }})
    }
  },

  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .main {
    background-color: white;
    max-width: 480px;
    box-sizing: border-box;
  }
  .span {
    color: white;
  }

</style>
