<template>
  <div id="">
    <v-app>
      <v-app-bar class="navbar " dark app>
        <v-btn icon @click="go">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <v-img
            max-height="100"
            max-width="100"
            src="./../public/logo.svg"
          ></v-img>
        </v-toolbar-title>
        <v-toolbar-title class="toolbarTitle">Reklamace</v-toolbar-title>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-container>
            <v-row>
              <p class="ml-5">Vaše rezervace:</p>
            </v-row>
            <v-row>
              <br />
              <v-col>
                <div
                  class="pa-2 roudedPrimary text-center text-no-wrap rounded-pill"
                >
                  116555
                </div>
              </v-col>
              <v-col  class="d-flex text-center">
                <h3 class="ml-2  ">
                     Ivan Hasek
                  </h3>
              </v-col>
              <v-col>
                <div
                  class="pa-1 mt-1 grey text-center roudedGrey  text-no-wrap rounded-pill"
                  @click="openCart"
                >
                  vise
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div class="vise-open" v-if="open">
            <div class="d-flex vise">
              <p class=" m-0 title">
                Vozidlo:
              </p>
              <p class=" m-0 description">
                Škoda Octavia
              </p>
            </div>

             <div class="d-flex vise">
              <p class="  m-0 ml-4 title">
                Barva:
              </p>
              <p class=" m-0 description">
               červená metalíza
              </p>
            </div>

             <div class="d-flex vise">
              <p class=" m-0 ml-7 title">
                SPZ:
              </p>
              <p class="  m-0 description">
               1EA 4532
              </p>
            </div>
          </div>
          <br />
          <v-divider></v-divider>
            <div class="text-center">
            <router-view></router-view>
          </div>
          <pre>{{testForm}}</pre>
          <br>
        <pre>{{DataComplain}} </pre>
        </v-container>
      </v-main>

      <v-footer app> </v-footer>
    </v-app>
  </div>
</template>

<script>
import {mapState,mapGetters} from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,axios)
export default {
  name: "",
  data: () => ({
    open: true,
  }),
  methods: {
    testsend(){
      this.axios.post("https://www.smileparking.cz/service.getreservation?hash=c56cf9",this.info)
    },
    openCart() {
      this.open = !this.open;
    },
    go(){
      this.$router.push({name:'Main'})
    },
     send() {
      const newInformation = {
          drivername:'drivername New newInformation',
          // type: this.type,
          // spz: this.spz,
          // id:this.id,
          // cars:this.cars
        };
      //  window.location.reload()
        axios.post("https://www.smileparking.cz/service.getreservation?hash=c56cf9", newInformation)
          .then((response) => {
            console.log(response);
          })
          .catch((e) => {
            console.log(e);
          });
      },

  },
    computed:{
      ...mapState(['testForm']),
      ...mapGetters(['DataComplain'])
    },
    mounted() {
   this.$router.push('/customer-complain')
    }

};
</script>

<style scoped>
.navbar {
  background: #cf4639 !important;
  position: initial!important;
  max-height: 70px;
}
.toolbarTitle {
  margin-left: 30px;
}
.roudedPrimary {
  background: #3987ad;
  color: white;
}
.roudedGrey {
  background: #c4c4c4;
  color: white;
  cursor: pointer;
}
.Main {
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.vise {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.title{
  margin: 0 0px 4px 0px!important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px!important;
  text-align: right;
  color: #999999;
}
.description{
  display: flex;
    margin: 0 0px 0px 8px!important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  color: #000000;
}

.vise-open {
  width: 100%;
}

</style>
